<template>

	<div class="setMenupage" v-loading="loading">
		
		<template v-if="!data.AppId">
			<noOfficialAccount  @refresh="getData"></noOfficialAccount>
		</template>
		
		<el-row v-else type="flex" class="row-bg">
			<el-col :span="13">
				<div class="content-word">
					<p>1.一级菜单最多设置3个</p>
					<p>2.每个一级菜单下最多设置5个二级菜单</p>
					<p>3.设置菜单链接 - 自定义链接，请输入完整的链接地址（例如：链接至百度页面，请填写完整地址：https：//www.baidu.com）</p>
				</div>

				<el-button type="primary" v-if="nemuList.length<3" @click="addFirstMenu">添加菜单</el-button>

				<!-- 菜单 -->
				<div class="menu-boxs" v-for="(item,index) in nemuList" :key="index" v-if="nemuList.length">
					<el-card class="box-card">
						<div class="el-icon-error close-btn" @click="delectMenue(item,index)"></div>

						<div slot="header" class="clearfix">
							<span style="margin-right: 15px;">一级菜单</span>
							<el-input placeholder="请输入内容" :maxlength="inputMax" v-model="item.Name" style="width:200px" clearable @input="changeValue(item,index)"></el-input>
							<span style="margin-left:15px;"> 字数不超过4个字或8个字母</span>
							<el-button style="float: right;" size="small" type="primary" @click="addMenue(item,index)" v-if="!item.dispose">展开</el-button>
							<el-button style="float: right;" size="small" type="primary" @click="addMenue(item,index)" v-if="item.dispose">收起</el-button>
						</div>

						<div class="content-word" v-if="!item.WxMenuChildList.length">
							<!-- <span style="margin-right:15px;">链接</span>
							<span style="color: #409EFF;cursor: pointer;" @click="selectPage(index)" v-if="!item.PageName">请选择页面</span>
							<span style="color: #409EFF;cursor: pointer;" @click="selectPage(index)" v-else>{{item.PageName}}</span>-->
							<el-radio-group v-model="item.radioType" @change="(e)=>{radioChangefun1(e,index)}">
								<el-radio :label="1" style="margin-bottom: 10px;" >
									<span style="margin-right:15px;">链接</span>
									<span style="color: #409EFF;cursor: pointer;" @click="selectPage(index)" v-if="!item.PageName">请选择页面</span>
									<span style="color: #409EFF;cursor: pointer;" @click="selectPage(index)" v-else>{{item.PageName}}</span>
								</el-radio>
								</br>
								<el-radio :label="3" style="margin:10px 0px" >
									<span style="margin-right:15px;">员工工作台
										<span style="color:#999999;">（选中后，员工点击该菜单，将跳转至员工工作台）</span>
									</span>
								</el-radio>
								</br>
								<el-radio :label="2">
									<span style="margin-right:15px;">自定义链接</span>
									<el-input placeholder="请输入链接地址" v-model="item.Url" style="width:200px" clearable></el-input>

								</el-radio>
							</el-radio-group>
						</div>

						<div class="addChild" @click="addChildMenu(item,index)" v-if="item.WxMenuChildList.length<5&&item.dispose">
							<span class="el-icon-circle-plus-outline" style="margin-right:6px"></span>添加子菜单
						</div>

						<!-- 二级菜单 -->
						<div style="position: relative;" v-show="item.dispose" v-for="(ites,index1) in item.WxMenuChildList" :key="index1"
						 v-if="item.WxMenuChildList.length">
							<div class="el-icon-error close-btn close-btn1" @click="delectMenue1(index,index1)"></div>
							<el-card class="box-card">
								<div slot="header" class="clearfix">
									<span style="margin-right: 15px;">二级菜单</span>
									<el-input placeholder="请输入内容" :maxlength="inputMax1" style="width:200px" v-model="ites.Name" clearable @input="changeValue1(index,index1)"></el-input>
									<span style="margin-left:15px;"> 字数不超过8个字或16个字母</span>
								</div>
								<!-- 菜单内容 -->
								<div class="content-word">
									<!-- <span style="margin-right:15px;">链接</span>
									<span style="color: #409EFF;cursor: pointer;" @click="selectsecondPage(index,index1)" v-if="!ites.PageName">请选择页面</span>
									<span style="color: #409EFF;cursor: pointer;" @click="selectsecondPage(index,index1)" v-else>{{ites.PageName}}</span>
									 -->
									<el-radio-group v-model="ites.radioType" @change="(e)=>{radioChangefun2(e,index,index1)}">
										<el-radio :label="1" style="margin-bottom: 10px;">
											<span style="margin-right:15px;">链接</span>
											<span style="color: #409EFF;cursor: pointer;" @click="selectsecondPage(index,index1)" v-if="!ites.PageName">请选择页面</span>
											<span style="color: #409EFF;cursor: pointer;" @click="selectsecondPage(index,index1)" v-else>{{ites.PageName}}</span>
										</el-radio>
										</br>
										<el-radio :label="3" style="margin:10px 0px" >
											<span style="margin-right:15px;">员工工作台
												<span style="color:#999999;">（选中后，员工点击该菜单，将跳转至员工工作台）</span>
											</span>
										</el-radio>
										</br>
										<el-radio :label="2">
											<span style="margin-right:15px;">自定义链接</span>
											<el-input placeholder="请输入链接地址" v-model="ites.Url" style="width:200px" clearable></el-input>
										</el-radio>
									</el-radio-group>

								</div>

							</el-card>

						</div>
					</el-card>

				</div>

			</el-col>

			<el-col :span="5" style="margin-left:50px">
				<img src="http://cdn.dkycn.cn/Images/PC/custom-top.png?v=1" alt="">
				<div class="center-content"></div>
				<el-row type="flex" class="row-bg" style="width:400px">
					<el-col :span="3">
						<img src="http://cdn.dkycn.cn/Images/PC/custom-nav.png" alt="" style="width:25px;margin-left:5px">
					</el-col>


					<el-col :span="24/(bothList.length)" class="show-menu" v-for="(ite,index) in bothList" :key="index">
						<div @click="showSecond(ite,index)">
							{{ite.Name}}

							<div class="second-menue" v-show="ite.showSeconds">
								<div class="menu-child" v-for="(iters,indes) in  ite.WxMenuChildList" :key="indes">{{iters.Name}}</div>
							</div>
						</div>
					</el-col>
				</el-row>

				<div style="margin-top:30px;width:400px">
					<el-button type="primary" @click="saveNemue" :loading="btnloading">保存(暂不生成)</el-button>
					<el-button type="primary" style="margin-left:25px;" @click="generateMenu" :loading="btnloading">生成菜单</el-button>
				</div>
			</el-col>

		</el-row>

		<!-- 选择的页面弹框 -->
		<link-modal :visible="visibleable" @change="dataChange" :isOfficialAccount="true" @cancel="visibleable=false" :unshowTab="[5]"></link-modal>


	</div>

</template>

<script>
	import linkModal from '../../components/linkModal';
	import {
		getWxMenu,
		saveWxMenu,
		createWxMenu,
		wxPublicPlatFromInfo
	} from '@/api/goods';
	
	import noOfficialAccount from '../components/noOfficialAccount.vue';
	
	export default {
		components: {
			linkModal,
			noOfficialAccount
		},
		data() {
			return {
				btnloading: false,
				loading: false,
				visibleable: false,
				nemuList: [],
				inputMax: 8,
				inputMax1: 16,
				bothList: [],
				CustomizeRadio: 0,
				customizeList: [],
				firstIndex: null,
				secondindex: null,
				secondindex1: null,
				selectPageIndex: null,
				
				data: {}

			}
		},
		beforeMount() {
			this.getMenu();
			this.getData();
		},
		methods: {
			async getData() {
				let result = await wxPublicPlatFromInfo()
				this.data = result.Result;
			},
			radioChangefun1(e,i){
				// console.log(this.nemuList[i])
				this.nemuList[i].PageName = ''
				this.nemuList[i].PagePath = ''
				this.nemuList[i].Url = ''
			},
			radioChangefun2(e,i,n){
				// console.log(this.nemuList[i].WxMenuChildList[n])
				this.nemuList[i].WxMenuChildList[n].PageName = ''
				this.nemuList[i].WxMenuChildList[n].PagePath = ''
				this.nemuList[i].WxMenuChildList[n].Url = ''
			},
			// radioChangefun(){
			// 	this.$forceUpdate()
			// },
			// 获取菜单
			async getMenu() {
				try {
					this.loading = true
					let result = await getWxMenu()
					var list = result.Result
					// console.log(list)
					list.map(item => {
						item.dispose = true
						item.showSeconds = false
						item.PageName = item.PageName
						if (item.radioType == 3||item.radioType == 1) {
							item.Url = ''
						}
						if (item.WxMenuChildList.length > 0) {
							item.WxMenuChildList.map(record => {
								record.PageName = record.PageName
								if (record.radioType == 3||record.radioType == 1) {
									record.Url = ''
								}
						return record
							})
						}

						return item
					})
					this.nemuList = list;
					this.bothList = list;
				} catch (e) {
					console.log(e)
				} finally {
					this.loading = false
				}

			},

			// 选择一级菜单页面
			selectPage(index) {
				this.visibleable = true
				this.firstIndex = index;
				this.selectPageIndex = 1;
				this.visibleable = true;
			},
			// 选择二级菜单页面
			selectsecondPage(index, index1) {
				this.secondindex = index;
				this.secondindex1 = index1;
				this.selectPageIndex = 2
				this.visibleable = true;
			},
			dataChange(record) {
				if (this.selectPageIndex == 1) {
					if (record.type == "classify") {
						this.nemuList[this.firstIndex].PageName = record.data.GroupName
						this.nemuList[this.firstIndex].MenuType = 1
						this.nemuList[this.firstIndex].PagePath = 'pages/productGroup/productGroup?id=' + record.data.Id
					} else if (record.type == "functional") {
						this.nemuList[this.firstIndex].PageName = record.data.name
						this.nemuList[this.firstIndex].MenuType = 2
						// 全部商品
						if (record.data.id == 1) {
							this.nemuList[this.firstIndex].PagePath = 'pages/index/index'
						} else if (record.data.id == 2) {
							this.nemuList[this.firstIndex].PagePath = 'pages/allProduct/allProduct'
						} else if (record.data.id == 3) {
							// 个人中心
							this.nemuList[this.firstIndex].PagePath = 'pages/personalCenter/mine/mine'
						} else if (record.data.id == 4) {
							// 购物车
							this.nemuList[this.firstIndex].PagePath = 'pages/shoppingCart/shoppingCart'
						} else if (record.data.id == 5) {
							// 积分中心
							this.nemuList[this.firstIndex].PagePath = 'pages/pointsCenter/pointsCenter'
						} else if (record.data.id == 6) {
							// 每日签到
							this.nemuList[this.firstIndex].PagePath = 'pages/userSign/userSign'
						} else if (record.data.id == 7) {
							// 客服
							this.nemuList[this.firstIndex].PagePath = 'pages/customerService/customerService'
						} else if (record.data.id == 8) {
							// 订单列表
							this.nemuList[this.firstIndex].PagePath = 'pages/order/myOrder/myOrder'
						} else if (record.data.id == 9) {
							// 分销礼包
							this.nemuList[this.firstIndex].PagePath = 'pages/invitation/invitationCard/invitationCard'
						}else if (record.data.id == 10) {
							// 成为会员
							this.nemuList[this.firstIndex].PagePath = 'pageA/pages/openSuccess/openSuccess'
						}else if (record.data.id == 11) {
							// 助力直播列表
							this.nemuList[this.firstIndex].PagePath = 'pageA/pages/live/live'
						}else if (record.data.id == 12) {
							// 扫码购
							this.nemuList[this.firstIndex].PagePath = 'pageA/pages/scan/scan'
						}else if (record.data.id == 13) {
							// 充值中心
							this.nemuList[this.firstIndex].PagePath = 'pageA/pages/balanceRecharge/balanceRecharge'
						}else if (record.data.id == 14) {
							// 群分享
							this.nemuList[this.firstIndex].PagePath = 'pageA/pages/groupShare/groupShare'
						}else if (record.data.id == 15) {
							// 全部信息页
							this.nemuList[this.firstIndex].PagePath = 'pageA/pages/information/information'
						}else if (record.data.id == 16) {
							// 短视频带货
							this.nemuList[this.firstIndex].PagePath = 'pageC/pages/shortVideos/record'
						}else if (record.data.id == 17) {
							// 积分码
							this.nemuList[this.firstIndex].PagePath = 'pageA/pages/receive-point/receive-point'
						}else if (record.data.id == 18) {
							// 舌诊
							this.nemuList[this.firstIndex].PagePath = 'pageC/pages/tongueDiagnosis/home'
						}else if (record.data.id == 19) {
							// 企店即创主页
							this.nemuList[this.firstIndex].PagePath = 'pageC/pages/AIGC/home'
						}else if (record.data.id == 20) {
							// 门店列表
							this.nemuList[this.firstIndex].PagePath = 'pages/choseDoor/choseDoor'
						}
						

					} else if (record.type == "product") {
						this.nemuList[this.firstIndex].PageName = record.data.Name
						this.nemuList[this.firstIndex].MenuType = 3
						this.nemuList[this.firstIndex].PagePath = 'pages/detail/detail?id=' + record.data.Id

					} else if (record.type == "custom") {
						this.nemuList[this.firstIndex].PageName = record.data.PageName
						this.nemuList[this.firstIndex].MenuType = 4
						this.nemuList[this.firstIndex].PagePath = 'pages/cusPage/cusPage?id=' + record.data.Id
					}	else if (record.type == 'truntable'){
						this.nemuList[this.firstIndex].PageName = record.data.Name
						this.nemuList[this.firstIndex].MenuType = 6
						this.nemuList[this.firstIndex].PagePath = 'pageA/pages/activity/truntable/truntable?id=' + record.data.Id
					} else if (record.type == 'coupon'){
						this.nemuList[this.firstIndex].PageName = record.data.CouponName
						this.nemuList[this.firstIndex].MenuType = 7
						this.nemuList[this.firstIndex].PagePath = 'pages/couponCenter/receive/receive?id='+record.data.Id+'&uid='+(record.data.uid || '')
					}else if (record.type == 'questionnaire'){
						this.nemuList[this.firstIndex].PageName = record.data.Title
						this.nemuList[this.firstIndex].MenuType = 10
						this.nemuList[this.firstIndex].PagePath = 'pageA/pages/questionnaire/questionnaire?&referer=4&id=' + record.data.Id;
					}else if (record.type == 'taketest') {
						this.nemuList[this.firstIndex].PageName = record.data.Name
						this.nemuList[this.firstIndex].MenuType = 11
						this.nemuList[this.firstIndex].PagePath = `pageC/pages/goodnessTest/home?id=` + record.data.Id
					}else if (record.type == 'contentgroup') {
						this.nemuList[this.firstIndex].PageName = record.data.GroupName
						this.nemuList[this.firstIndex].MenuType = 12
						this.nemuList[this.firstIndex].PagePath = `pageA/pages/information/informationGroup?id=${record.data.Id}`
					}else if (record.type == 'singlecontent') {
						this.nemuList[this.firstIndex].PageName = record.data.Title
						this.nemuList[this.firstIndex].MenuType = 13
						this.nemuList[this.firstIndex].PagePath = `pageA/pages/information/detail?id=${record.data.Id}`
					}else if (record.type == 'helpPro') {
						this.nemuList[this.currrentIndex].PageName = record.data.ProductName
						this.nemuList[this.currrentIndex].MenuType = 14
						this.nemuList[this.currrentIndex].PagePath = `pageC/pages/helpPro/helpPro?id=${record.data.ProductId}&activityId=${record.data.ActivityFullId}`
					}

				} else if (this.selectPageIndex == 2) {

					if (record.type == "classify") {
						this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].PageName = record.data.GroupName
						this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].MenuType = 1
						this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].PagePath =
							'pages/productGroup/productGroup?id=' + record.data.Id

					} else if (record.type == "functional") {
						this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].PageName = record.data.name
						this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].MenuType = 2
						// 全部商品
						if (record.data.id == 1) {
							this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].PagePath = 'pages/index/index'
						}else if (record.data.id == 2) {
							this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].PagePath = 'pages/allProduct/allProduct'
						} else if (record.data.id == 3) {
							// 个人中心
							this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].PagePath = 'pages/personalCenter/mine/mine'
						} else if (record.data.id == 4) {
							// 购物车
							this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].PagePath = 'pages/shoppingCart/shoppingCart'
						} else if (record.data.id == 5) {
							// 积分中心
							this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].PagePath = 'pages/pointsCenter/pointsCenter'
						} else if (record.data.id == 6) {
							// 每日签到
							this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].PagePath = 'pages/userSign/userSign'
						} else if (record.data.id == 7) {
							// 客服
							this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].PagePath =
								'pages/customerService/customerService'
						} else if (record.data.id == 8) {
							// 订单列表
							this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].PagePath = 'pages/order/myOrder/myOrder'
						} else if (record.data.id == 9) {
							// 分销礼包
							this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].PagePath =
								'pages/invitation/invitationCard/invitationCard'
						}else if (record.data.id == 10) {
							// 成为会员
							this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].PagePath = 'pageA/pages/openSuccess/openSuccess'
						}else if (record.data.id == 11) {
							// 助力直播列表
							this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].PagePath = 'pageA/pages/live/live'
						}else if (record.data.id == 12) {
							// 扫码购
							this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].PagePath = 'pageA/pages/scan/scan'
						}else if (record.data.id == 13) {
							// 扫码购
							this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].PagePath = 'pageA/pages/balanceRecharge/balanceRecharge'
						}else if (record.data.id == 14) {
							// 群分享
							this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].PagePath = 'pageA/pages/groupShare/groupShare'
						}else if (record.data.id == 15) {
							// 全部信息页
							this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].PagePath = 'pageA/pages/information/information'
						}else if (record.data.id == 16) {
							// 短视频带货
							this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].PagePath = 'pageC/pages/shortVideos/record'
						}else if (record.data.id == 17) {
							// 积分码
							this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].PagePath = 'pageA/pages/receive-point/receive-point'
						}else if (record.data.id == 18) {
							// 舌诊
							this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].PagePath = 'pageC/pages/tongueDiagnosis/home'
						}else if (record.data.id == 19) {
							// 企店即创主页
							this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].PagePath = 'pageC/pages/AIGC/home'
						}

					} else if (record.type == "product") {
						this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].PageName = record.data.Name
						this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].MenuType = 3
						this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].PagePath = 'pages/detail/detail?id=' + record.data.Id
					} else if (record.type == "custom") {
						this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].PageName = record.data.PageName
						this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].MenuType = 4
						this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].PagePath = 'pages/cusPage/cusPage?id=' +	record.data.Id
					}else if (record.type == 'truntable'){
						this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].PageName = record.data.Name
						this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].MenuType = 6
						this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].PagePath = 'pageA/pages/activity/truntable/truntable?id=' + record.data.Id
					}else if (record.type == 'coupon'){
						this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].PageName = record.data.CouponName
						this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].MenuType = 7
						this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].PagePath = 'pages/couponCenter/receive/receive?id='+record.data.Id+'&uid='+(record.data.uid || '')
					}else if (record.type == 'questionnaire'){
						this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].PageName = record.data.Title
						this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].MenuType = 10
						this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].PagePath = 'pageA/pages/questionnaire/questionnaire?&referer=4&id=' + record.data.Id;
					}else if (record.type == 'taketest') {
						this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].PageName = record.data.Name
						this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].MenuType = 11
						this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].PagePath = `pageC/pages/goodnessTest/home?id=` + record.data.Id
					}else if (record.type == 'contentgroup') {
						this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].PageName = record.data.GroupName
						this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].MenuType = 12
						this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].PagePath = `pageA/pages/information/informationGroup?id=${record.data.Id}`
					}else if (record.type == 'singlecontent') {
						this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].PageName = record.data.Title
						this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].MenuType = 13
						this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].PagePath = `pageA/pages/information/detail?id=${record.data.Id}`
					}else if (record.type == 'helpPro') {
						this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].PageName = record.data.ProductName
						this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].MenuType = 14
						this.nemuList[this.secondindex].WxMenuChildList[this.secondindex1].PagePath = `pageC/pages/helpPro/helpPro?id=${record.data.ProductId}&activityId=${record.data.ActivityFullId}`
					}
			
				}

				// console.log(record, '一级菜单')
			},
			// 添加一级菜单
			addFirstMenu() {
				let obj = {
					Name: '',
					MenuType: '',
					radioType: '',
					MenuId: '',
					PagePath: '',
					Url: '',
					dispose: true,
					showSeconds: false,
					PageName: '',
					WxMenuChildList: []
				}

				this.nemuList.push(obj)

			},
			// 查看二级菜单
			showSecond(record, index) {
				this.bothList[index].showSeconds = !this.bothList[index].showSeconds;
				this.$forceUpdate()
			},

			//单选按钮
			getProRow(row) {
				this.checked = true
				this.selection = row
			},
			// 展开，收起
			addMenue(record, index) {
				this.nemuList[index].dispose = !this.nemuList[index].dispose;
				this.$forceUpdate()
			},

			// 删除一级菜单
			delectMenue(item, index) {
				this.$confirm('确认删除此菜单吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.nemuList.splice(index, 1)
				}).catch(() => {
					this.$message({showClose: true,
						type: 'info',
						message: '已取消删除'
					});
				}).finally(() => {
					
				})
			},
			// 删除二级级菜单
			delectMenue1(index, index1) {
				this.$confirm('确认删除此菜单吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {

					this.nemuList[index].WxMenuChildList.splice(index1, 1)


				}).catch(() => {
					this.$message({showClose: true,
						type: 'info',
						message: '已取消删除'
					});
				}).finally(() => {

				})
			},
			// isLegal(str) {
			// 	return /^[a-zA-Z]{1,8}$/.test((str + '').replace(/[\u4e00-\u9fa5]/g, 'aa'))
			// },
			// isLegal2(str) {
			// 	return /^[a-zA-Z]{1,16}$/.test((str + '').replace(/[\u4e00-\u9fa5]/g, 'aa'))
			// },

			// // 长度的限制
			// changeValue(item, index) {
			// 	if (!this.isLegal(this.nemuList[index].Name)) {
			// 		this.$message({showClose: true,
			// 			type: 'error',
			// 			message: '字数不超过4个字或8个字母'
			// 		});
			// 		this.nemuList[index].Name = ''
			// 	}

			// },

			// changeValue1(index, index1) {
			// 	if (!this.isLegal2(this.nemuList[index].WxMenuChildList[index1].Name)) {
			// 		this.$message({showClose: true,
			// 			type: 'error',
			// 			message: '字数不超过8个字或16个字母'
			// 		});
			// 		this.nemuList[index].WxMenuChildList[index1].Name = ''
			// 	}

			// },
			
			
			// 长度的限制
			changeValue(item, index) {
			
				let leng = this.validateTextLength(this.nemuList[index].Name);
			
				if (leng >= 4) {
			
					this.inputMax = 4
				} else {
					this.inputMax = 8;
				}
			
			},
			
			changeValue1(index, index1) {
			
				let leng = this.validateTextLength(this.nemuList[index].WxMenuChildList[index1].Name);
			
				if (leng >= 8) {
			
					this.inputMax1 = 8
				} else {
					this.inputMax1 = 16;
				}
			
			},
			
			validateTextLength(value) {
				// 中文、中文标点、全角字符按1长度，英文、英文符号、数字按0.5长度计算
				let cnReg = /([\u4e00-\u9fa5]|[\u3000-\u303F]|[\uFF00-\uFF60])/g
				let mat = value.match(cnReg)
				let length
				if (mat) {
					length = (mat.length + (value.length - mat.length) * 0.5)
					return length
				} else {
					return value.length * 0.5
				}
			},

			addChildMenu(record, index) {
				if (!record.WxMenuChildList.length) {
					this.$confirm('添加二级菜单后，一级菜单设置的内容将被清除。确定添加子菜单？', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.nemuList[index].PageName = '';
						this.addChild(index)
					}).catch(() => {
						this.$message({showClose: true,
							type: 'info',
							message: '已取消操作'
						});        
					});
				}else{
					this.addChild(index)
				}
			},
			addChild(index) {
				let obj = {
					Name: '',
					MenuType: '',
					Url:'',
					radioType: '',
					MenuId: '',
					PagePath: '',
					PageName: '',
				}
				// this.nemuList[index].Name=''
				this.nemuList[index].MenuType = ''
				this.nemuList[index].PagePath = ''
				this.nemuList[index].WxMenuChildList.push(obj)
			},
			geturl(){
				let secondParam = window.localStorage.getItem('mlmzMallKey')
				return ('https://wapapi.qidian.shop/sa/#/pages/index/index?mallkey='+secondParam)
			},
			// 保存菜单，暂不生成
			async saveNemue() {
				// return;
				try {
					this.btnloading = true
					let arrysData = []
					this.nemuList.map(item => {
						if (!item.Name) {
							arrysData.push(item)
						}
						if (item.radioType == 2) {
							item.MenuType = 5
							item.PagePath = ''
						} else if(item.radioType == 1) {
							item.Url = ''
						}else if(item.radioType == 3){
							item.Url = this.geturl()
							item.MenuType = 5
							item.PagePath = ''
						}

						item.WxMenuChildList.map(record => {
							if (!record.Name) {
								arrysData.push(record)
							}
							if (record.radioType == 2) {
								record.MenuType = 5
								record.PagePath = '';
								record.PageName = '';
							} else  if(record.radioType == 1){
								record.Url = ''
							}else if(record.radioType == 3){
								record.Url = this.geturl()
								record.MenuType = 5
								record.PagePath = ''
							}
						})

					})

					if (arrysData.length > 0) {
						this.$message({showClose: true,
							type: 'error',
							message: '请完善菜单信息'
						});
						return
					}
					this.bothList = this.nemuList;
					console.log(this.nemuList)
					let result = await saveWxMenu({
						MenuList: this.nemuList
					})
					if (result.IsSuccess) {
						this.$message({showClose: true,
							type: 'success',
							message: '操作成功'
						});
						this.getMenu()
					}


				} catch (e) {
					console.log(e)
				} finally {
					this.btnloading = false;
				}


			},

			// 生成菜单
			async generateMenu() {
				try {
					this.btnloading = true;
					let arrysData = []
					this.nemuList.map(item => {
						if (!item.Name) {
							arrysData.push(item)
						}
						if (item.radioType == 2) {
							item.MenuType = 5
							item.PagePath = ''
						} else  if(item.radioType == 1){
								item.Url = ''
							}else if(item.radioType == 3){
								item.Url = this.geturl()
								item.MenuType = 5
								item.PagePath = ''
							}

						item.WxMenuChildList.map(record => {
							if (!record.Name) {
								arrysData.push(record)
							}
							if (record.radioType == 2) {
								record.MenuType = 5
								record.PagePath = ''
								record.PageName = '';
							} else  if(record.radioType == 1){
								record.Url = ''
							}else if(record.radioType == 3){
								record.Url = this.geturl()
								record.MenuType = 5
								record.PagePath = ''
							}
						})

					})

					if (arrysData.length > 0) {
						this.$message({showClose: true,
							type: 'error',
							message: '请完善菜单信息'
						});
						return
					}

					this.bothList = this.nemuList;
					let result = await createWxMenu({
						MenuList: this.nemuList
					})
					if (result.IsSuccess) {
						this.$message({showClose: true,
							type: 'success',
							message: '操作成功'
						});
						this.getMenu()
					}
				} catch (e) {
					console.log(e)
				} finally {
					this.btnloading = false;
				}
			},
		}
	}
</script>

<style lang="less" scoped>
	.setMenupage {
		background: #fff;
		padding: 15px;

		.content-word {
			margin-bottom: 30px;
			font-size: 15px;
			margin-bottom: 15px;
		}

		.menu-boxs {
			// width: 500px;
			position: relative;

			.box-card {
				margin-top: 30px;
				// width: 500px;
			}

			.close-btn {
				cursor: pointer;
				position: absolute;
				right: -8px;
				top: -8px;
				font-size: 25px;
			}

			.addChild {
				color: #409EFF;
				margin-top: 20px;
				cursor: pointer;
			}
		}

		.center-content {
			width: 400px;
			height: 500px;
			background: #f5f7fa;
		}

		.show-menu {
			text-align: center;
			background: #fff;
			height: 34px;
			line-height: 34px;
			border-right: 1px solid #eee;
			box-sizing: border-box;
			position: relative;
			font-size: 13px;

			.second-menue {
				position: absolute;
				bottom: 34px;
				left: 50%;
				transform: translateX(-50%);
				-webkit-transform: translateX(-50%);
				width: 110px;
				text-align: center;
				background: #fff;

				.menu-child {
					width: 100%;
					height: 35px;
					line-height: 35px;
					border-bottom: 1px solid #eee;
				}

			}
		}

	}
</style>
